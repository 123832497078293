var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.results
    ? _c(
        "div",
        { staticClass: "pi-hole-queries-wrapper" },
        _vm._l(_vm.results, function (section) {
          return _c(
            "div",
            { key: section.id, staticClass: "query-section" },
            [
              _c("p", { staticClass: "section-title" }, [
                _vm._v(_vm._s(section.title)),
              ]),
              _vm._l(section.results, function (query, i) {
                return _c("div", { key: i, staticClass: "query-row" }, [
                  _c("p", { staticClass: "domain" }, [
                    _vm._v(_vm._s(query.domain)),
                  ]),
                  _c("p", { staticClass: "count" }, [
                    _vm._v(_vm._s(query.count)),
                  ]),
                ])
              }),
            ],
            2
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }